.timeline {
    margin: 30px 0;
}

.timeline__item:after {    
    background-color: #9a03e0;
    border: 1px solid #9a03e0;
}

.timeline__item--right .timeline__content:before {
    border-right: 12px solid #9a03e0;
}

.timeline__content {
    border: 2px solid #9a03e0;
    border-radius: 0;
    display: flex;
    padding: 0;
}

.timeline__content:before {
    border-left: 12px solid #9a03e0;
}
.timeline__content:after {
    border-bottom: 9px solid transparent;
    border-left: 11px solid #fff;
    border-top: 9px solid transparent;
    right: -10px;
    z-index: 2;
}

.timeline__content .evDate {
    display: flex;
    flex-direction: column;  
}

.timeline__content .evDate .evDay {
    font-size: 2.5rem;
    line-height: 2rem; 
    padding: 20px 20px 0 20px;
}

.timeline__content .evDate .evMonth {
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 0 20px 20px 20px;
}

.timeline__content .description {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgb(237, 237, 237);
}

.timeline__content p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 20px 10px;
}



/* .timeline--horizontal .timeline__item .timeline__content:before {
    border-top: 12px solid #9a03e0;
}
.timeline--horizontal .timeline__item .timeline__content:after {
    border-top: 10px solid rgb(237, 237, 237);
}

.timeline--horizontal .timeline__item--bottom .timeline__content:before {
    border-bottom: 12px solid #9a03e0;
}
.timeline--horizontal .timeline__item--bottom .timeline__content:after {
    border-bottom: 10px solid rgb(237, 237, 237);
} */

.timeline-nav-button--prev:before {
    background-image: url(../img/arrow-left.svg);
}

.timeline-nav-button--next:before {
    background-image: url(../img/arrow-right.svg);
}

.timeline--mobile .timeline__item .timeline__content:before {
    left: -12px;
    border-bottom: 11px solid transparent;
    border-right: 11px solid #9a03e0;
    border-top: 11px solid transparent;
}
