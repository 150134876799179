:root {
	--primary-color: #933cc5;
	--secondary-color: #8a15ce;
	--background-color: #ffffff;
	--background-color-light: #f1f1f1;
	--background-color-extra-light: #fafafa;
	--background-color-dark: #383d4c;
	--background-color-footer: #2e3445;
	--font-color: #707070;
	--secondary-font-color: #bdbdbd;
	--third-font-color: #d0d0d0;
	--dark-background-hover-color: #c033ff;
	--container-size: 59.17%;
	--secondary-container-size: 59.17%;
	--nav-height: 85px;
	--mini-nav-height: 30px;
	--primary-font: "Source Sans Pro", sans-serif;
}

/* ---------------------------- MAIN ---------------------------- */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
}

body {
	overflow-x: hidden;
	font-family: var(--primary-font);
	position: relative;
	color: var(--background-color-dark);
	font-size: 1.6rem;
}

article ol, 
article ul {
	padding-left: 40px;
}

select {
	appearance: none;
	background-color: transparent;
	border: none;
	padding: 0 1.6em 0 0;
	margin: 0;
	width: 100%;
	font-family: inherit;
	font-size: inherit;
	cursor: inherit;
	line-height: inherit;
	outline: none;
}

button {
	border: none;
	background-color: hsl(0 0% 100%);
	line-height: 0;
}

h1,
h2,
h3 {
	font-family: var(--primary-font);
	margin: 0;
	padding: 0;
}

h3 {
	font-size: clamp(2.7rem, 2.5333rem + 0.3472vw, 3.2rem);
	color: var(--secondary-font-color);
}

strong {
	font-weight: 600;
}

a {
	color: var(--primary-color);
}

a:hover {
	color: var(--secondary-color);
}

#root > div {
	min-height: 100vh;
}

.wp-block-embed__wrapper iframe,
.newsItemWrapper div iframe {
	width: 100% !important;
	height: auto;
	aspect-ratio: 1.778 !important;
}

.wp-block-image figure.aligncenter {
	text-align: center;
}

p.has-text-align-center {
	text-align: center;
}

p.has-border {
	border: 2px solid;
}

p.border-gray {
	border-color: var(--secondary-font-color);
}

p.text-gray {
	color: var(--secondary-font-color);
}

p.has-space-top {
	margin-top: 25px;
}

p.has-space-bottom {
	margin-bottom: 25px !important;
}

p.has-interior-space-top {
	padding-top: 15px;
}

p.has-interior-space-bottom {
	padding-bottom: 15px;
}

p.line-spacing-small {
	line-height: 2rem !important;
}

p.has-small-font-size {
	font-size: 1.2rem;
}

p.has-large-font-size {
	font-size: 2.6rem;
}

p.has-x-large-font-size {
	font-size: 3.2rem;
}

p.has-background {
	padding: 0.8rem 1.2rem;
}

.container {
	max-width: var(--container-size);
}

.container-fluid {
	padding: 0;
}

.not-found {
	text-align: center;
  }
  
  .not-found h1 {
	font-size: 10rem;
	margin-bottom: 1rem;
	color: #343a40;
  }
  
  .not-found p {
	font-size: 2rem;
	margin-bottom: 2rem;
	color: #6c757d;
  }

.hide {
	height: 0;
	visibility: hidden;
}

.show {
	height: auto;
	visibility: visible;
}

.hideMenu {
	opacity: 0;
	visibility: hidden;
}

.showMenu {
	opacity: 1;
	visibility: visible;
}

.color-primary {
	color: var(--primary-color);
}

.full-col-width {
	max-width: 100%;
}

.partial-col-width {
	max-width: 70ch;
}

.nav-bb {
	border-bottom: 1px solid var(--background-color-light);
}

.toTopIcon {
	position: fixed;
	cursor: pointer;
	bottom: 12.8rem;
	right: 3.2rem;
	color: white;
	width: 40px;
	height: 40px;
	background-color: var(--font-color);
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	transition: all 200ms ease-in-out;
}

.toTopIcon:hover {
	color: white;
	text-decoration: none;
}

/* ---------------------------- HEADER ---------------------------- */

/* ---------------------- Mobile Menu ---------------------- */
.mobileHeader {
	width: 100vw;
	position: fixed;
	z-index: 9999;
	background-color: hsl(0 0% 100%);
	top: 0;
	left: 0;
	right: 0;
}

.mobileHeader .container {
	padding: 1rem 4rem;
	display: flex;
	justify-content: space-between;
}

.mobileHeader img {
	width: 11rem;
}

.mobileButtons {
	list-style: none;
	display: flex;
	gap: 2rem;
	margin: 0;
}

.mobileButtons li {
	display: flex;
	align-items: center;
}

.mobileNav {
	position: fixed;
	z-index: 9998;
	height: 100vh;
	left: 35vw;
	top: 0;
	right: 0;
	overflow-x: hidden;

	background-color: hsl(0 0% 100%);

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	transform: translateX(100%);
	transition: transform 350ms ease-in-out;
}

.mobileNav[data-visible="true"] {
	transform: translateX(0%);
}

.mobileNav ul {
	list-style: none;
	display: flex;
	flex-direction: column;

	transition: all 350ms ease-in-out;
}

.mobileNav > ul {
	height: calc(100% - 10rem);
}

.mobileNav ul li {
	padding: 1.5rem 2rem;
	border-bottom: 1px solid hsl(0, 0%, 90%);
}

.mobileNav ul li:hover {
	background-color: hsl(0, 0%, 90%);
}

.mobileNav ul span,
.mobileNav ul div {
	color: var(--font-color);
	line-height: 2.7rem;

	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.mobileNav ul li.appsMobile {
	flex-grow: 1;
	display: flex;
	align-items: flex-end;
	padding: 0;
}

.mobileNav ul li.appsMobile:hover {
	background-color: hsl(0, 0%, 100%);
}

.mobileNav ul li.appsMobile > div {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	padding: 1.5rem 2rem;
	border-top: 1px solid hsl(0, 0%, 90%);
	border-bottom: 1px solid hsl(0, 0%, 90%);
}

.mobileNav ul li.appsMobile > div:hover {
	background-color: hsl(0, 0%, 90%);
}

.mobileNav ul li.appsMobile > div a {
	width: 100%;
	text-decoration: none;
}

.mobileNav ul li.appsMobile > div a span {
	justify-content: center;
}

.mFirstLevel,
.mSecondLevel,
.mThirdLevel {
	position: absolute;
	width: 100%;
	left: 0;
}

.mFirstLevel {
	top: 5rem;
}

.mSecondLevel,
.mThirdLevel {
	top: 0;
}

.mThirdLevel a {
	text-decoration: none;
	color: var(--font-color);
	display: block;
}

.mBtnBack {
	font-size: 1.4rem;
}

li.mNavTitle {
	background-color: hsl(0, 0%, 85%);
	font-weight: 600;
	font-size: 1.8rem;
}

.mobileNav ul[data-visible="true"] {
	opacity: 1;
	visibility: visible;
}

.mobileNav ul[data-visible="false"] {
	opacity: 0;
	visibility: hidden;
}

.mobileNav ul[data-position="left"] {
	transform: translateX(-100%);
}

.mobileNav ul[data-position="center"] {
	transform: translateX(0%);
}

.mobileNav ul[data-position="right"] {
	transform: translateX(100%);
}

/* ---------------------- Main Menu ---------------------- */

.navbar.topNav > .container {
	height: var(--nav-height);
	flex-wrap: nowrap;
}

.navbar-brand {
	margin: 0;
	padding: 0;
	width: 35%;
}

.navbar-brand img {
	width: 215px;
}

/* ------------------- Mini Menu ------------------- */

.miniNav {
	position: relative;
	z-index: 99999;
	height: var(--mini-nav-height);
	background-color: var(--background-color-light);
}

.miniNav .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: var(--mini-nav-height);
}

.spacer {
	height: 20px;
	border-right: 2px solid var(--secondary-font-color);
}

.sobre-ipb {
	height: var(--mini-nav-height);
}

.sobre-ipb ul {
	list-style: none;
}

.sobre-ipb .appButton > ul {
	display: flex;
	gap: 3rem;
}

.menu-sobre {
	height: var(--mini-nav-height);
	display: flex;
	align-items: center;
}

.menu-sobre:hover .sobreList {
	opacity: 1;
	visibility: visible;
}

.menu-sobre:hover > div {
	color: var(--primary-color);
}

.menu-sobre > div {
	font-size: 1.4rem;
	cursor: pointer;
}

.sobreList {
	position: absolute;
	top: calc(var(--mini-nav-height) - 1px);
	z-index: 999;
	padding-bottom: 1rem;
	background-color: var(--background-color-light);
	color: var(--font-color);
	font-size: 1.4rem;
	width: 250px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s ease-in-out;
}

.sobreList li {
	padding: 0.5rem 2rem;
	cursor: pointer;
}


.sobreList li:last-child {
	padding-bottom: 1rem;
}

.sobreList li:first-child {
	padding-top: 1rem;
}

.sobreList li:hover a {
	color: var(--primary-color);
}

.sobreList li a {
	color: var(--font-color);
	text-decoration: none;
	display: block;
}

.topButtons {
	display: flex;
	align-items: center;
	color: var(--font-color);
}

.topButtons ul {
	margin: 0;
	list-style: none;
}

.topButtons > div {
	padding-left: 10px;
}

.topButtons .material-icons {
	font-size: 1.9rem;
}

.btn-top {
	display: inline-flex;
	height: var(--mini-nav-height);
	align-items: center;
	justify-content: center;
	border-radius: 0;
}

.btn-top:focus {
	outline: none;
	box-shadow: none;
}

.langButton {
	display: flex;
	gap: 1rem;
}

.langButton span {
	cursor: pointer;
	font-size: 1.4rem;
}

.langButton span:hover {
	color: var(--primary-color);
}

.appButton {
	position: relative;
}

.appButton:hover .appsList {
	visibility: visible;
	opacity: 1;
}

.apps {
	width: var(--mini-nav-height);
	color: var(--primary-color);
	cursor: pointer;
}

.apps:hover {
	color: var(--font-color);
}

.appsList {
	position: absolute;
	top: var(--mini-nav-height);
	right: -165px;
	z-index: 999;
	background-color: var(--background-color-light);
	color: var(--font-color);
	text-align: left;
	font-size: 1.6rem;
	width: 230px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s ease-in-out;
}

.appsList li a {
	width: 100%;
	display: inline-block;
	padding: 1.2rem 2.8rem;
	text-decoration: none;
	color: var(--font-color);
	transition: all 0.25s ease-in-out;
}

.appsList li a:hover {
	background-color: var(--primary-color);
	color: var(--background-color-light);
}

.search {
	font-size: 1.9em;
	width: var(--mini-nav-height);
	background-color: var(--background-color-light);
}

.search:hover {
	color: var(--primary-color);
}

.miniUrlButton button {
	color: var(--font-color);
	width: var(--mini-nav-height);
	background-color: var(--background-color-light);
}

.miniUrl {
	font-size: 1.3em;	
}

.miniUrl:hover {
	color: var(--primary-color);
}

/* ------------------- Menu ------------------- */
.navMenu {
	width: 100%;
	list-style: none;
	display: flex;
	margin: 0;
}

.navPage {
	background-color: #ffffff;
}

div.navMenus {
	height: 100%;
	align-items: stretch;
}

.navMenus {
	display: flex;
	width: 70%;
}

.navbar.topNav {
	padding: 0;	
	background-color: #ffffff;
	position: relative;
	z-index: 9998;
}

.navMenu.firstLevel .navMenu-item .mActive {
	font-weight: bold;
	color: var(--primary-color);
}

/* .navbar .mActive::after {
	content: "";
	background: var(--primary-color);
	position: absolute;
	left: 0;
	bottom: 0;
	height: 2px;
	width: 35px;
} */

.navMenu.firstLevel {
	justify-content: space-between;
}

.navMenu.secondLevel {
	position: absolute;
	padding: 5rem 0 1.6rem 0;
	left: 0;
	top: 100%;
	z-index: 999;
	background-color: var(--background-color);	
	border-top: 3px solid var(--primary-color);
	box-shadow: 0px 2px 5px var(--secondary-font-color);
	width: 100vw;
	height: clamp(38.2rem, 19.6rem + 14.5312vw, 47.5rem);
	visibility: hidden;
	opacity: 0;
	/* display: none; */
	transition: 170ms all ease-in-out;
	
}

.navMenu.secondLevel > .container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 2rem;
	align-items: start;
}

.navMenu.thirdLevel {
	display: flex;
	flex-direction: column;
	padding-top: 2rem;
	gap: 10px;
}

.navMenu.thirdLevel .navMenu-item a img {
	width: 80%;
	transition: all 200ms ease-in-out;
}

.navMenu.thirdLevel .navMenu-item a img:hover {
	transform: scale(1.1);
}

.navMenu.thirdLevel > li a {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.firstLevel > .navMenu-item {
	display: flex;
	align-items: center;
	/* width: 100%; */
	justify-content: center;
}

.firstLevel > .navMenu-item > span {
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	color: var(--font-color);
}

.firstLevel > .navMenu-item:hover > span {
	color: var(--secondary-color);
	cursor: pointer;
}

/* .firstLevel > .navMenu-item:hover > span::after {
	content: "";
	background: var(--secondary-color);
	position: absolute;
	left: 0;
	bottom: 0;
	height: 2px;
	width: 35px;
} */

/* .firstLevel > .navMenu-item:hover .navMenu.secondLevel {
	visibility: visible;
	opacity: 1;
} */

/* .secondLevel .container > .navMenu-item > a > span {
	color: #000000;
	font-weight: 600;
} */

.secondLevel .container > .navMenu-item > a > div {
	color: #000000;
	font-weight: 600;
}

.thirdLevel > .navMenu-item:hover > a {
	color: var(--primary-color);
}

.navMenu-item a {
	height: 100%;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: var(--font-color);
}

.navMenu-item:hover > a {
	color: var(--primary-color);
}

/* ------------------- Modal Search ------------------- */

#searchModal {
	z-index: 10000;
}

.modal-header {
	border-bottom: 0px;
	width: var(--secondary-container-size);
	margin: auto;
	align-items: center;
	margin-bottom: 15vh;
	padding: 4rem;
	width: 100vw;
}

.modal-header img {
	width: 200px;
}

.modal-header .close {
	text-shadow: none;
	color: var(--primary-color);
	font-size: 4.8rem;
	line-height: 2.3rem;
	font-weight: 200;
	font-family: var(--primary-font);
	border: 1px solid var(--primary-color);
	border-radius: 50%;
}

.modal-dialog {
	max-width: 100%;
	height: 100vh;
	margin: 0;
}

.modal-content {
	height: 100vh;
	color: var(--font-color);
	display: flex;
	flex-direction: column;
	border: 0;
	border-radius: 0;
	background: var(--background-color);
	overflow-x: hidden;
	overflow-y: scroll;
}

.modal-body {
	width: var(--secondary-container-size);
	margin: auto;
}

.inputText {
	border-bottom: 1px solid var(--font-color);
	display: flex;
	gap: 2rem;
}

.inputText span {
	margin-bottom: 0;
	font-size: 3.8rem;
	color: var(--secondary-font-color);
}

.inputText input {
	width: 100%;
	background: transparent;
	outline: none;
	border: 0;
	color: var(--secondary-font-color);
	font-size: 2.4rem;
	line-height: 4.5rem;
}

.searchRes .titulo {
	display: inline-block;
	margin-top: 3rem;
	font-size: 2.4rem;
}

.searchRes .note {
	font-size: 1.4rem;
	color: var(--secondary-font-color);
	font-weight: bold;
	color: var(--font-color);
}

.searchRes .servico {
	font-size: 1.4rem;
	color: var(--font-color);
	color: black;
}

.searchRes ul {
	list-style: none;
}

.searchRes ul li {
	margin: 1.6rem 0 0 0;
	font-size: 1.8rem;
	font-weight: 300;
}

.searchRes ul li p {
	font-size: 1.8rem;
	color: black;	
}

.pessoasRes,
.siteRes,
.noticiasRes,
.eventosRes {
	max-height: 75rem;
	overflow-y: auto;
	margin-top: 20px;
}

.noticiasRes button,
.eventosRes button,
.siteRes button {
	text-decoration: underline;
	color: var(--primary-color);
}

.noticiasRes button:hover,
.eventosRes button:hover,
.siteRes button:hover {
	/* color: var(--font-color); */
	font-weight: bold;
}

/* Navbar Pages */

/* ---------------------------- PAGES ---------------------------- */

/* Home Page */

.heroSection {
	display: flex;
	flex-direction: column;
	height: calc(100vh - var(--mini-nav-height) - var(--nav-height));
	overflow: hidden;
}

#homeSlider {
	flex-grow: 1;
	overflow: hidden;
}

.carousel-inner {
	height: 100%;
}

.carousel-item {
	height: 100%;
	color: #fff;
}

.carouselImg {
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
}

.carousel-caption {
	left: 0;
	right: 0;
	bottom: 0;
	padding: clamp(1.6rem, 0.6714rem + 1.1607vw, 2.9rem) 3.2rem;
	text-align: start;
	background-color: rgba(0, 0, 0, 0.3);
}

.carousel-caption p {
	margin: 0;
}

.carousel-caption h3 {
	color: var(--background-color);
}

.carousel-caption a {
	text-decoration: none;
	color: #fff;
	font-weight: 500;
	font-size: 3.2rem;
}

.carousel-indicators li {
	border: 1px solid var(--third-font-color);
}

/* .carousel-indicators {
	margin-bottom: 9.1rem;
} */

a.carousel-control-prev,
a.carousel-control-next {
	width: 15%;
}

a.carousel-control-prev:hover {
	cursor: w-resize;
}

a.carousel-control-next:hover {
	cursor: e-resize;
}

.bottomBar {
	padding: 0;
}

ul.icons {
	width: 100%;
	margin: 0;
	padding: 1.9rem 0;
	list-style: none;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
	gap: 1rem;
	align-items: baseline;
}

li.icon a {
	height: 100%;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

li.icon a:hover span.material-icons-outlined {
	color: var(--secondary-color);
	transform: scale(1.1);
}

li.icon a:hover span.textIcon {
	color: var(--secondary-color);
}

li.icon a span.material-icons-outlined {
	font-size: 3.5rem;
	color: var(--secondary-font-color);
	transition: 200ms transform ease-in-out;
}

li.icon a span {
	margin-top: 15px;
	color: var(--font-color);
	text-align: center;
}

.news .title,
.agenda .title {
	border-bottom: 2px solid #525053;
	line-height: 5.1rem;
}

.timeline__items {
	margin: auto !important;
}

.timeline__content {
	color: var(--background-color-dark);
}

.timeline--horizontal .timeline-divider {
	height: 3px;
}

.newsSection {
	padding: 100px 0;
	background-color: #f1f1f1;
}

.featureNewsCard {
	margin-bottom: 7.5rem;
	background-color: var(--background-color-light);
}

.featureNewsCard .cardImg {
	height: 100%;
	overflow: hidden;
	position: relative;
}

.featureNewsCard a {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(377px, 1fr));
	gap: 3.6rem;
	height: 100%;
}

.featureNewsCardContent {
	padding: 3.6rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.featureSumary {
	font-size: 1.4rem;
}

.featureDate {
	font-weight: bold;
	margin: 0;
}

.newsCards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
	gap: 3.6rem;
}

.newsCardsHome {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
	gap: 3.6rem;
}

.newsCards .card,
.newsCardsHome .card {
	border: none;
}

.newsCards .card > a,
.newsCardsHome .card > a,
.featureNewsCard > a {
	text-decoration: none;
	color: var(--background-color-dark);
}

.news .card-title p {
	font-size: 2.1rem;
	margin: 0;
}

.news .card-date {
	font-size: 1.6rem;
	font-weight: 300;
	padding: 1.8rem;
}

.card-date.news {
	padding-top: 0.96rem;
	font-weight: bold;
}

.cardImg {
	width: 100%;
	aspect-ratio: 1.778;
	overflow: hidden;
	position: relative;
}

.cardImg img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	width: 100%;
	transition: 200ms all ease-in-out;
}

.card .card-body.newsList {
	padding: 1.8rem 0;
}

.card-body {
	display: flex;
	flex-direction: column;
	padding: 1.25rem 1.25rem 3.5rem 1.25rem;
	line-height: 3rem;
}

.card > a:hover .cardImg > img,
.featureNewsCard > a:hover .cardImg > img {
	transform: translateY(-50%) translateX(-50%) scale(1.1);
}

.card > a:hover .card-body,
.featureNewsCard > a:hover .featureNewsCardContent .featureTitle {
	color: var(--primary-color);
}

/* Paginação */

.newsListWrapper > ul {
	display: flex;
	list-style: none;
	gap: 1.2rem;
	justify-content: center;
	margin: 2.5rem 0;
}

.newsListWrapper > ul li {
	border: 1px solid var(--secondary-font-color);
	padding: 0.5rem 1.2rem;
}

.newsListWrapper > ul li.selected {
	color: var(--primary-color);
	text-decoration: underline;
}

.newsListWrapper > ul li.disabled a {
	color: var(--font-color);
	text-decoration: none;
	cursor: default;
}

article.moreLevels {
	background-color: var(--background-color-light);
}

/* ------------------------------------ */

.moreNews,
.moreEvents {
	margin-top: 8rem;
	display: flex;
	justify-content: center;
}

.moreNews a,
.moreEvents a {
	padding: 0.64rem 1.28rem;
	background-color: var(--primary-color);
	color: white;
	text-decoration: none;
}

.moreNews a:hover,
.moreEvents a:hover {
	background-color: var(--secondary-color);
}

.timeline__content a {
	font-size: 1.9rem;
	line-height: 1.3;
	padding: 1.6rem 1.05rem;
	text-decoration: none;
	color: var(--font-color);
}

.timeline__content a:hover {
	color: var(--secondary-color);
}

.card-text {
	flex-grow: 1;
	overflow-y: hidden;
}

.agendaSection {
	padding: 100px 0;
}

#evMes {
	font-size: 2.4rem;
}

/* Lista de Notícia */

.newsListWrapper {
	margin-top: 4.8rem;
}

.newsListWrapper .topBar {
	display: flex;
	align-items: baseline;
	gap: 1.6rem;
	margin-bottom: 7.5rem;
}

.newsListWrapper .topBar .newsTitle {
	flex-grow: 1;
}

.newsTitle span {
	font-size: 8rem;
}

.newsListWrapper .topBar input {
	width: 50ch;
	border: 1px solid var(--font-color);
	font-size: 1.28rem;
	padding: 0.96rem 1.28rem;
	text-align: left;
	outline: none;
}

.filtro {
	display: flex;
	height: 6.4rem;
	margin: 3.2rem 0;
	position: relative;
}

.filtro select {
	width: 15ch;
	background-color: var(--font-color);
	font-size: 2.3rem;
	color: var(--background-color);
	font-weight: 400;
	cursor: pointer;
	height: 100%;
	padding: 0 1.6rem;
	border-radius: 0;
	box-shadow: none;
	border-top: 1px solid var(--font-color);
	border-bottom: 1px solid var(--font-color);
}

.filtro .select::after {
	content: "";
	width: 1.3rem;
	height: 1rem;
	background-color: var(--background-color);
	position: absolute;
	top: 50%;
	right: 1.9rem;
	transform: translateY(-50%);
	clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

/* .filtro select::after {
  content: "\02C5";
  font-size: 3.4em;
  line-height: 0.8em;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  pointer-events: none;
} */

.meses {
	flex-grow: 1;
	border-top: 1px solid var(--secondary-color);
	border-bottom: 1px solid var(--secondary-color);
}

.meses ul.mesesList {
	list-style: none;
	height: 100%;
	display: flex;
	margin: 0;
}

.meses ul.mesesList li {
	flex-basis: 100%;
	font-size: 1.9rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.meses ul.mesesList li:hover {
	cursor: pointer;
	color: var(--background-color);
	background-color: var(--secondary-color);
}

.meses ul.mesesList li.selected {
	color: var(--background-color);
	background-color: var(--secondary-color);
	position: relative;
}

.meses ul.mesesList li.selected::after {
	content: "";
	width: 20px;
	aspect-ratio: 1;
	background-color: var(--secondary-color);
	position: absolute;
	top: 100%;
	left: 50%;
	transform: rotate(45deg) translateY(10%) translateX(-70%);
}

.mesesSel {
	display: none;
}

/* Detalhe de Notícia */

.container.newsItem {
	position: relative;
	margin-top: 4.8rem;
}

.newsItemWrapper {
	width: min(80ch, 100%);
	margin: auto;
	padding-bottom: 6rem;
}

.newsItemWrapper h3 {
	font-size: 3.2rem;
}

.newsItemWrapper p {
	word-wrap: break-word;
}

.newsItemWrapper span.newsDate {
	display: inline-block;
	font-size: 1.9rem;
	margin: 1.9rem 0 3.2rem 0;
}

.newsItemWrapper img.newsItemImage {
	width: 100%;
	margin-bottom: 4.8rem;
}

.newsItemWrapper p.newsResumo {
	font-size: 1.9rem;
}

.newsItemWrapper span.newsBack {
	font-size: clamp(5rem, 3.1633rem + 4.0816vw, 11rem);
	color: var(--primary-color);
	position: fixed;
	left: clamp(3rem, -34.7143rem + 36.8304vw, 36rem);
}

.newsItemWrapper span.newsBack:hover {
	color: var(--background-color);
	background-color: var(--primary-color);
}

/* Lista e Detalhe de Curso */

.course-detail .bg-color {
	background-color: var(--background-color-light);
}

.course-title-detail {
	font-size: 2rem;
}

.course-back {
	font-size: clamp(4rem, 3.3878rem + 1.3605vw, 6rem);
	cursor: pointer;
	color: var(--primary-color);
}

.course-back:hover {
	color: var(--background-color);
	background-color: var(--primary-color);
}

.maisInfo .card.cardAccordion {
	border: initial;
	border-bottom: 1px solid var(--background-color-dark) !important;
}

.maisInfo .card-header {
	background-color: var(--background-color);
	padding: initial;
	padding-top: 0.5rem;
}

.maisInfo .card-header button {
	font-size: 1.6rem;
	cursor: pointer;
	width: 100%;
	padding: 0.375rem 0;
	display: flex;
	justify-content: space-between;
	margin: 0;
}

.maisInfo .card-header button[aria-expanded="false"] > span::after {
	font-family: "Material Icons";
	content: "expand_more";
	font-size: 2.4rem;
	line-height: 1rem;
	padding: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga";
	font-feature-settings: "liga";
}

.maisInfo .card-header button[aria-expanded="true"] > span::after {
	font-family: "Material Icons";
	content: "expand_less";
	font-size: 2.4rem;
	line-height: 1rem;
	padding: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga";
	font-feature-settings: "liga";
}

.maisInfo .card-header button:focus,
.plano .card-header button:focus {
	outline: none;
	box-shadow: none;
}

.maisInfo .card-header button > strong,
.plano .card-header button > strong {
	text-align: left;
}

.maisInfo .card-header button > span,
.plano .card-header button > span {
	position: relative;
	padding: 0;
	line-height: 1;
	color: var(--primary-color);
}

.plano .card.cardAccordion {
	border: initial;
}

.plano .card-header {
	background-color: var(--background-color);
	padding: initial;
	border: initial;
}

.plano .card-header button {
	font-size: 1.6rem;
	cursor: pointer;
	width: 100%;
	padding: 0;
	display: flex;
	gap: 10px;
	margin: 0;
	position: relative;
}

.plano .card-header button[aria-expanded="false"] > span::after {
	font-family: "Material Icons";
	content: "expand_more";
	font-size: 2.4rem;
	line-height: 1rem;
	padding: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga";
	font-feature-settings: "liga";
	position: absolute;
	top: 6px;
}

.plano .card-header button[aria-expanded="true"] > span::after {
	font-family: "Material Icons";
	content: "expand_less";
	font-size: 2.4rem;
	line-height: 1rem;
	padding: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga";
	font-feature-settings: "liga";
	position: absolute;
	top: 6px;
}

.plano .card-body {
	padding: 0;
	background-color: var(--background-color-extra-light);
}

.plano.opcao .card-body table tr.no-border td {
	border-bottom: initial;
}

.curso-search {
	border-bottom: 1px solid var(--secondary-font-color);
	display: flex;
	gap: 2rem;
	margin-top: 3rem;
}

.curso-search span {
	margin-bottom: 0;
	font-size: 3rem;
	color: var(--secondary-font-color);
}

.curso-search input {
	width: 100%;
	background: transparent;
	outline: none;
	border: none;	
	font-size: 1.8rem;
	line-height: 3rem;
}

.curso-lista {
	margin-top: 3rem;
}

.areas-cientificas-detail,
.plano-estudos-detail,
.curso-lista {
	width: 100%;
	border-spacing: 0;
}

.plano-estudos-detail .opcao {
	cursor: pointer;
}

.plano-estudos-detail .opcao-uc {
	background-color: var(--background-color-extra-light);
}

.curso-lista tr th {
	color: #a0a0a0;
	font-weight: inherit;
	border-bottom: 2px solid #f1eefd;
}

.curso-lista tr {
	color: #484848;
}

.curso-lista tr:nth-child(even) {
	background-color: #f1f1f1;
}

.curso-lista.curso tr td:nth-child(2) {
	width: 30%;
}

.curso-lista tr th,
.curso-lista tr td {
	padding: 0.4rem 1rem;
	border-collapse: separate;
}

.areas-cientificas-detail tr td,
.areas-cientificas-detail tr th {
	padding: 0.8rem 1.2rem;
	border-collapse: separate;
}

.areas-cientificas-detail tr td {
	border-bottom: 4px solid var(--background-color);
}

.plano-estudos-detail tr th,
.plano-estudos-detail tr td {
	padding: 0.4rem 1.5rem 0.4rem 0;
	border-collapse: separate;
}

.plano-estudos-detail tr td.opcao {
	padding: 0;
	border-collapse: separate;
}

.plano-estudos-detail tr td {
	border-bottom: 3px solid var(--background-color-light);
}

.curso-lista tr td span {
	margin-left: 1.5rem;
}

/* Single Pages */

.pageOverlay {
	position: absolute;
	background-color: var(--background-color-dark);
	inset: 0;
	z-index: 999;
	pointer-events: none;
	opacity: 0;
	transition: all 200ms;
}

.imgDestaque {
	background-size: cover;
	background-position: center;
	position: relative;
	height: clamp(30rem, 24.6429rem + 11.9048vw, 47.5rem);
	/* height: clamp(20rem, 16.4184rem + 7.9592vw, 31.7rem); */
}

.imgDestaque .titleBar {
	position: absolute;
	width: 100vw;
	z-index: 997;
	bottom: 0;
	height: clamp(5rem, 1.2727rem + 5.3788vw, 11.6rem);
	display: flex;
	align-items: center;
}

.imgDestaque .titleBar .overlayBar {
	position: absolute;
	opacity: 0.4;
	inset: 0;
	background: var(--background-color-dark);
	z-index: 998;
}

.imgDestaque .titleBar h1 {
	color: var(--background-color);
	font-size: clamp(3.3rem, 1.8612rem + 3.1973vw, 8rem);
	font-weight: 600;
	position: relative;
	z-index: 999;
}
.wp-block-columns {
	display: flex;
	gap: 2rem;
	margin-bottom: 2rem;
}

.wp-block-column {
	min-width: 0;
	word-break: break-word;
	overflow-wrap: break-word;
	flex: 1;
}

.is-vertically-aligned-center {
	align-self: center;
}

.is-vertically-aligned-bottom {
	align-self: end;
}

.container.page.title {
	margin-top: 6rem;
}

/* .container.page.title h3 {
	position: relative;
	margin-bottom: 2rem;
}

.container.page.title h3::after {
	content: "";
	position: absolute;
	width: 69px;
	height: 3px;
	left: 0;
	bottom: -16px;
	background-color: var(--primary-color);
} */
.container.page.title .renderedTitle {
	position: relative;
	margin-bottom: 2rem;
	font-size: clamp(2.7rem, 2.5333rem + 0.3472vw, 3.2rem);
	color: var(--secondary-font-color);
	line-height: 4rem;
}

.container.page.title .renderedTitle::after {
	content: "";
	position: absolute;
	width: 69px;
	height: 3px;
	left: 0;
	bottom: -16px;
	background-color: var(--primary-color);
}


.page .wp-block-columns .wp-block-column p,
.wp-block-column figure {
	margin: 0;
}

.page .content p {
	margin-bottom: 1.25rem;
	line-height: 2.75rem;
}

section.content {
	min-height: 32vh;
}

section article:last-child {
	padding-bottom: 9rem !important;
	margin-bottom: 0 !important;
}

section ol li {
	margin-left: 4rem;
}
section figure img,
article figure img {
	width: 100%;
	height: auto;
}

/* Webdocs */

div.webdocs-accordion div.webdocs-card button.webdocs-title {
	border-bottom: 1px solid var(--secondary-font-color);
	margin-bottom: 0.5rem;
	margin-top: 1rem;
	padding-bottom: 0.5rem;
	width: 100%;
	font-size: 1.6rem;

	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.webdocs-accordion div.webdocs-title {
	padding-left: 0.75rem;
}

.webdocs-accordion .webdocs-title span.buttonWebdocs {
	color: var(--primary-color);
	padding: 0 0.5rem;
}

.webdocs-accordion div.webdocs-card div.card-header button.webdocs-title span.buttonWebdocs::after {
	font-size: 1.6rem;
}

.webdocs-accordion .webdocs-card {
	border: none;
}

.webdocs-accordion .webdocs-card button {
	color: var(--primary-color);
}

.webdocs-accordion .webdocs-card button.collapsed {
	color: var(--background-color-dark);
}

.webdocs-accordion .webdocs-card button[aria-expanded="false"] > span::after {
	content: "+";
}

.webdocs-accordion .webdocs-card button[aria-expanded="true"] > span::after {
	content: "-";
}

.webdocs-accordion .webdocs-card .card-body {
	padding: 0 0 0 2rem;
}

.webdocs-accordion .webdocs-card .card-body .webdocs-docs a {
	text-decoration: underline;
	color: var(--background-color-dark);
	font-weight: 600;
}

.webdocs-accordion .webdocs-card .card-body .webdocs-docs a:hover {
	color: var(--primary-color);
}

.webdocs-accordion .webdocs-card .card-body .webdocs-docs p {
	font-size: 1.4rem;
	line-height: 2.5rem;
}

.webdocs-accordion .webdocs-card .card-header {
	border: none;
	padding: 0;
}

.webdocs-accordion .webdocs-card button:focus {
	outline: none;
	box-shadow: none;
}

/* Serviços Centrais */

.menus-servicos {
	margin-top: 3rem;
}

.menus-servicos hr {
	margin-bottom: 4rem;
}

.menus-servicos > :last-child {
	display: none;
}

.menus-servicos .servico-name {
	text-align: center;
	line-height: 2.5rem;
	font-size: 2.5rem;
	font-weight: 600;
	background-color: #e0e1e3;
	padding: 2rem;
}

.menus-servicos .servico-name a {
	color: var(--background-color-dark);
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: flex-end;
}

.menus-servicos .servico-name a span:first-child:hover {
	color: var(--primary-color);
	text-decoration: underline;
}

.menus-servicos .servico-name a:hover {
	text-decoration: none;
}

.menus-servicos .servico-list {
	display: flex;
	gap: 2px;
	align-items: stretch;
	width: 100%;
	text-align: center;
	list-style: none;
	margin-top: 2px;
}

.menus-servicos .servico-list li {
	background-color: #d3d5d6;
	padding: 2rem 0;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.menus-servicos .servico-list li a {
	color: var(--background-color-dark);
}

.menus-servicos .servico-list li a:hover {
	color: var(--primary-color);
}

.menus-servicos > hr {
	width: 100%;
	height: 1px;
	background-color: var(--primary-color);
}

/* Mais niveis de Menu */

.next-level-menu ul {
	list-style: none;
}

.next-level-menu ul li {
	padding: 2.5rem 0;
	border-bottom: 1px solid var(--secondary-font-color);
}

.next-level-menu ul li p:first-child {
	font-size: clamp(2.3rem, 2.1776rem + 0.2721vw, 2.7rem);
	color: var(--secondary-font-color);
}

.next-level-menu ul li p:nth-child(2) {
	width: 60%;
	color: var(--font-color);
}

.next-level-menu ul li p a {
	color: var(--font-color);
}

.next-level-menu ul li p a:hover {
	color: var(--primary-color);
	text-decoration: none;
}

.next-level-menu ul li div.buttons {
	display: flex;
	gap: 1.5rem;
	justify-content: flex-end;
	padding: 0 1.5rem;
}

.next-level-menu ul li div.buttons a {
	text-decoration: none;
	padding: 0.8rem 3rem;
	border: 1px solid var(--primary-color);
}

.next-level-menu ul li div.buttons a:hover {
	background-color: var(--primary-color);
	color: var(--background-color);
}

/* Breadcrums */

.breadcrums {
	font-size: 1.3rem;
	margin: 1rem 0;
}

.breadcrums ul {
	list-style: none;
	display: flex;
	align-items: center;
	gap: 0.8rem;
}

/* MiniUrl */

.ipb-url-dialog {
	background-color: #fff;
	padding: 2rem;
}

.ipb-url-dialog div {
	margin-bottom: 1.5rem;
}

.ipb-url-dialog div.buttons {
	display: flex;
	gap: 1rem;
	justify-content: flex-end;
}

.ipb-url-dialog div input {
	color: var(--font-color);
	margin-top: 1rem;
}

/* Horários */

#content h2 {
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 2.3rem;
}

.lang select,
#horarios select {
	width: 500px;
	height: 30px;
	padding: 0  5px;
	border: 1px solid var(--background-color-dark);
	border-radius: 5px;
	margin-bottom: 15px;
	font-size: 1.4rem;
	appearance: none;	
	background: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22currentColor%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E') no-repeat right center/1.5em;
}

#horarios .item {
	display: flex;
	flex-direction: column;
}

#horarios .item label.label {
	font-weight: bold;
}

#horarios .buttonDiv.item {
	text-align: left;
	height: 35px;
}

#horarios .buttonDiv.item button {
	height: 20px;
	width: 100px;
	border: 1px solid var(--third-font-color);
	border-radius: 5px;
	padding: 15px 0;
}

#horarios .buttonDiv.item button:hover {
	background-color: var(--font-color);
	color: var(--background-color);
}

/* ---------------------------- FOOTER ---------------------------- */

.separator {
	padding: 0.3rem 0;
	background: var(--primary-color);
}

.infoFooter {
	background-color: var(--background-color-footer);
}

.infoFooter .container > ul {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 2rem;
	margin: 0;
	color: var(--secondary-font-color);
	list-style: none;
	padding-bottom: 4.5rem;
	font-size: 2rem;
}

.infoFooter .container > ul > li {
	padding-top: 4.5rem;
}

.infoFooter .container > ul > li.ipb-news a:hover {
	background-color: var(--secondary-color);
}

.infoFooter .container > ul ul {
	list-style: none;
	padding-top: 2rem;
	font-size: 1.6rem;
}

.infoFooter .container > ul ul li {
	padding: 0.5rem 0;
}

.infoFooter .container > ul ul li a {
	color: var(--third-font-color);
	text-decoration: none;
}

.infoFooter .container > ul ul li a:hover {
	color: var(--dark-background-hover-color);
}

.infoFooter .ipb-news {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.infoFooter .ipb-news a {
	color: var(--background-color);
	font-size: 1.4rem;
	background-color: var(--primary-color);
	margin-top: 0.7rem;
	text-align: center;
	padding: 0.75rem 0;
	text-decoration: none;
}

.ContactsFooter {
	background-color: var(--background-color-light);
	padding: 1rem 0;
}

.ContactsFooter .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
}

.ContactsFooter .container img {
	width: 215px;
}

.ContactsFooter .container .footerLogo {
	flex-grow: 1;
}

.ContactsFooter .container .contacts {
	flex-grow: 1;
	font-size: 1.4rem;
}

.redesSociais ul {
	list-style: none;
	display: inline-flex;
}

.redesSociais ul li {
	margin-right: 2rem;
}

.redesSociais ul li a {
	color: var(--background-color-dark);
	text-decoration: none;
}

.protocolosProjetos {
	padding: 2rem 0;
	margin: 0;
}

.protocolosProjetos ul {
	list-style: none;
	display: flex;
	justify-content: center;
	gap: 2rem;
}

.protocolosProjetos img {
	height: 30px;
}

.footerContainer {
	padding: 7rem 0;
}

/* ---------------------------- MEDIA QUERY ---------------------------- */

@media screen and (max-width: 1400px) {
	:root {
		--container-size: 1024px;
	}
}

@media screen and (min-width: 1025px) {
	.mobileHeader {
		display: none;
	}

	#mobileNavigation {
		transform: translateX(100%);
	}
}

@media screen and (max-width: 1024px) {
	:root {
		--container-size: 90%;
	}

	section {
		margin-top: 20px;
	}

	section.infoFooter,
	section.ContactsFooter {
		margin-top: 0;
	}

	section.ContactsFooter .container {
		flex-direction: column;
		gap: 2rem;
	}

	.miniNav {
		display: none;
	}

	.topNav {
		display: none;
	}

	.mobileHeader .container {
		padding: 1rem 1.5rem;
	}

	.carousel-caption p {
		display: none;
	}

	.carousel-indicators {
		display: none;
	}

	.newsListWrapper .topBar {
		flex-direction: column;
		margin-bottom: 4rem;
	}

	.newsListWrapper > ul > li:not(.previous, .next) {
		display: none;
	}

	.newsTitle span {
		font-size: 5rem;
	}

	.pesquisaNews {
		width: 100%;
	}

	.newsListWrapper .topBar input {
		width: 100%;
	}

	.newsItemWrapper span.newsBack {
		font-size: 5rem;
		position: relative;
		left: 0;
	}

	.menus-servicos .servico-list {
		flex-direction: column;
	}

	.footerSpacer {
		display: none;
	}

	.next-level-menu ul li p:nth-child(2) {
		width: 100%;
	}

	.next-level-menu ul li div.buttons {
		padding: 1rem 1.5rem 0 1.5rem;
	}

	.meses {
		display: none;
	}

	.mesesSel {
		display: block;
	}
}

@media screen and (max-width: 800px) {
	.next-level-menu {
		width: 100%;
		margin-top: 2rem;
	}

	.next-level-menu ul {
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}

}

@media screen and (max-width: 599px) {
	#mobileNavigation {
		left: 0;
	}
}
